import * as React from "react";
import "./app.css";
import Post from "../components/Post";
import { graphql } from "gatsby";
import { truncateLong } from "../utils/helpers";
import { Layout } from "../components/Layout";

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Layout title={"Interaction Compendium"}>
      {edges.map((edge) => {
        const {
          title,
          slug,
          date,
          tags,
          product_link,
          product_link_name,
          featured_video,
          featured_video_id,
          featured_video_start,
          featured_video_host,
          referrer_url,
          referrer_name,
          author,
        } = edge.node.frontmatter;
        return (
          <Post
            slug={slug}
            date={date}
            title={title}
            tags={tags}
            product_link={product_link}
            product_link_name={product_link_name}
            featured_video={featured_video}
            featured_video_id={featured_video_id}
            featured_video_start={featured_video_start}
            featured_video_host={featured_video_host}
            referrer_url={referrer_url}
            referrer_name={referrer_name}
            author={author}
            html={truncateLong(edge.node.html)}
          />
        );
      })}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/data/catalog/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            slug
            date
            title
            tags
            product_link
            product_link_name
            featured_video
            featured_video_id
            featured_video_start
            featured_video_host
            referrer_url
            referrer_name
            author
          }
        }
      }
    }
  }
`;
